<template>
  <div class="modal custom-modal" v-bind:style="entity ? 'display: block' : ''"  tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ "Admin.modal.text" | staticTranslation }}</h5>
          <button type="button" class="close" v-on:click="cancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" v-on:click="confirm(entity.id)">{{ "Admin.modal.accept" | staticTranslation }}</button>
          <button type="button" class="btn btn-secondary" v-on:click="cancel()">{{ "Admin.modal.deny" | staticTranslation }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: ["confirm", "cancel", "entity"],
}
</script>

<style scoped>

.custom-modal {
  top: 150px;
}

</style>
