<template>
  <div id="adminComponent">
    <div class="row">
      <div class="col-lg-10 offset-lg-1">
        <div class="row admin-header">
          <h3 class="col-lg-10">{{ title }}</h3>
          <div class="col-lg-2">
            <button type="button" class="btn btn-success float-lg-right" v-on:click="create">{{ addText }}</button>
          </div>
        </div>
        <div v-if="!draggable">
          <div class="admin-list-card card card-body" v-bind:key="entity.id" v-for="entity in entities">
            <div class="card-title row">
              <div class="col-lg-9">
                <p class="admin-line-name">{{ getNameFunction(entity) | translation }}</p>
              </div>
              <div class="col-lg-3 pt-lg-1 text-right">
                <i class="btn btn-danger bi-trash-fill admin-action-image" v-on:click="deleting = entity"/>
                <i class="btn btn-primary bi-pencil-square admin-action-image" v-on:click="edit(entity.id)"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="draggable">
          <draggable v-model="entities" @change="saveOrder">
            <div class="admin-list-card card card-body" v-bind:key="entity.id" v-for="entity in entities">
              <div class="card-title row">
                <div class="col-lg-9">
                  <p class="col-lg-11 admin-line-name">{{ getNameFunction(entity) | translation }}</p>
                </div>
                <div class="col-lg-3 pt-lg-1 text-right">
                  <i v-if="draggable" class="bi-list col-lg-1" style="font-size: 1.5rem"/>
                  <i class="btn btn-danger bi-trash-fill admin-action-image" v-on:click="deleting = entity"/>
                  <i class="btn btn-primary bi-pencil-square admin-action-image" v-on:click="edit(entity.id)"/>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <error-modal :message="error"/>
    <confirmation-modal :confirm="remove" :cancel="() => deleting = null" :entity="deleting"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import ErrorModal from "@/components/admin/ErrorModal";
import ConfirmationModal from "@/components/admin/ConfirmationModal";
import draggable from 'vuedraggable'

export default {
  name: "AdminListComponent",
  props: [
    'title',
    'addText',
    'editPath',
    'entityName',
    'getNameFunction',
    'draggable'
  ],
  components: {
    'error-modal': ErrorModal,
    'confirmation-modal': ConfirmationModal,
    'draggable': draggable
  },
  created() {
    this.refresh()
  },
  data() {
    return {
      entities: [],
      error: null,
      deleting: null,
    }
  },
  methods: {
    create: function () {
      this.$router.push({path: this.editPath})
    },
    remove: function (id) {
      this.deleting = null;
      axios
          .get("/" + this.entityName + "/delete", {params: {id: id}})
          .then(() => {
            this.refresh();
          }).catch(error => this.error = error)
    },
    edit: function (id) {
      this.$router.push({path: this.editPath + id})
    },
    refresh: function () {
      axios
          .get("/" + this.entityName + "/list")
          .then((success) => {
            this.entities = success.data
          }).catch(error => this.error = error)
    },
    saveOrder: function () {
      let newIds = this.entities.map(entity => entity.id);
      axios
          .post("/" + this.entityName + "/order", newIds)
          .then(() => {
            this.refresh()
          }).catch(error => this.error = error)
    }
  }
}
</script>

<style scoped>
#adminComponent {
  overflow: hidden;
}

p {
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.admin-header {
  margin-top: 10px;
  margin-bottom: 45px;
}

.admin-header h3 {
  font-size: 18pt;
}

.card-title {
  margin-bottom: 0;
}

.admin-action-image {
  margin: 0 5px
}

.admin-line-name {
  font-size: 15pt;
}

.admin-list-card {
  padding: 5px 20px;
  margin: 5px;
}

</style>
